const accordionItems = document.querySelectorAll('.accordion-item');

accordionItems.forEach(item => {
    const button = item.querySelector('.accordion-button');
    const content = accordionContent(item);

    button.addEventListener("click", (e) => {
        e.preventDefault();

        const itemActive = item.classList.contains("active");

        accordionItems.forEach(otherItem => {
            const otherContent = accordionContent(otherItem);

            otherContent.style.maxHeight = null;
            otherItem.classList.remove("active");
        });

        if (!itemActive) {
            content.style.maxHeight = content.scrollHeight + 'px';
            item.classList.add("active");
        }
    });
});

function accordionContent(item) {
    return item.querySelector('.accordion-content')
}
