import 'iconify-icon';
import Swiper from 'swiper/bundle';

import './components/accordion';
// import "./components/header";
// import "./components/scroll";
// import "./components/scroll-up";
// import "./components/vh-check";
// import "./components/slide-down";
// import "./components/contact-form";

// import "bootstrap/dist/js/bootstrap.bundle";
// import "bootstrap/dist/js/bootstrap.esm";
// import "bootstrap/dist/js/bootstrap";

// import "./firebase/firebase";

/* Accordion change colors */

let cards = document.getElementsByClassName("card-header");

for (let i = 0; i < cards.length; i++) {
    cards[i].addEventListener("click", function () {
        let active = this.classList.contains("active");
        [...cards].forEach(function (card) {
            card.classList.remove("active");
        });
        if (!active) {
            this.classList.add("active");
        }
    });
}

/* nice select  */
import "jquery-nice-select";

$(document).ready(function () {
    $("select").niceSelect();
});

// Swipers
// Reviews
const reviews = new Swiper('.reviews .swiper', {
    spaceBetween: 24,
    slidesPerView: 1,
    breakpoints: {
        576: {
            slidesPerView: 2,
        },
        /*768: {
            slidesPerView: 2,
        },*/
    },
    navigation: {
        prevEl: '.reviews .swiper-button-prev',
        nextEl: '.reviews .swiper-button-next',
    },
    pagination: {
        el: '.reviews .swiper-pagination',
        // dynamicBullets: true,
        clickable: true,
    }
});
